import Post from './blogPost'
import { useState } from "react"
import React from 'react'
import PropTypes from 'prop-types';
import { useReducer } from 'react';
import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';



const BlogPosts = ({ posts, categories }) => {


    const [loadMore,setLoadMore] = useReducer((load) => !load,true )

    const fewerPosts = (posts,numberOfPosts) =>{
        return posts.slice(0,numberOfPosts)
    }
    const threePosts = fewerPosts(posts,3)

    const [filteredPosts, setFilteredPosts] = useState(threePosts)
    const [currentFilter, setCurrentFilter] = useState(null)

    const filterPosts = (name) => {
        if (currentFilter === name) {
            setCurrentFilter(null)
            setFilteredPosts(posts)
        } else if (name === 'All') {
            setCurrentFilter('All')
            setFilteredPosts(posts)
        } else {
            const filteredPostList = posts.filter((post) => {
                return post?.category.some(cat => cat?.name === name)
            })
            setCurrentFilter(name)
            setFilteredPosts(filteredPostList)
        }
    }

    const loadMorePosts = () =>{
        setLoadMore()
        if(loadMore){
            setFilteredPosts(posts)
        } else {
            setFilteredPosts(threePosts)
        }

    }



    const BlogPost = ({ post }) => {
        const { name, featureImage, date } = post
        return (
            <Post title={name} date={date} image={featureImage} />
        )
    }

    const FilterButton = ({ category, filterPosts, currentFilter }) => {
        const { name } = category
        return (
            <button className={('button outline mr10 mb5 ' + (currentFilter === name ? 'active' : ''))} onClick={() => { filterPosts(name) }}>{name}</button>
        )
    }

    return (
        <div className="blog--posts">
            <TransitionGroup className="filter--bar p20 flex flex-wrap align-center">
                <span className="large mr20 mb5">Filter</span>
                <FilterButton category={{ name: 'All' }} filterPosts={filterPosts} currentFilter={currentFilter} />
                {categories?.map((category) => {
                return (
                    <FilterButton category={category} filterPosts={filterPosts} key={category?.name}  currentFilter={currentFilter} />
                )})}
            </TransitionGroup>
            <TransitionGroup className="all--posts p20 pt40 m-pt20 grid gap-v-60" style={{ backgroundColor: '#ECECEC' }}>
            {filteredPosts?.map((post) => {
            return (
                <CSSTransition  timeout={0}  key={post?.name}>
                <BlogPost post={post}  />
                </CSSTransition>
            )})}
            </TransitionGroup>
            <div className="text-center p20" style={{ backgroundColor: '#ECECEC' }}>
                <button className="button black mb20 mt20" onClick={() =>{loadMorePosts()}}>{loadMore ? 'Load More' : 'Load Less'}</button>
            </div>
        </div>
    )
}



export default BlogPosts

BlogPosts.propTypes ={
    posts:PropTypes.array,
    categories:PropTypes.array
}