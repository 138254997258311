import React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import BlogPosts from "../components/blogPosts"
import Resources from "../components/resources"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const BlogPage = ({ data }) => {
  const seoInfo = data.allDatoCmsBlogPage?.nodes[0]
  return (
    <Layout>
      <Seo description={seoInfo?.metaDescription} title={seoInfo?.metaTitle} />
      <Introduction title="Knowledge & Insights" subtitle="We ensure that we are up to date with legislative developments and community engagement with family law issues." />
      <BlogPosts posts={data.allDatoCmsBlogPost?.nodes} categories={data.allDatoCmsBlogCategory?.nodes} />
      <Resources resources={data.resources} />
    </Layout>)

}


export const query = graphql`
query {

    allDatoCmsBlogPage {
      nodes {
        metaDescription
        metaTitle
      }
    }
    allDatoCmsBlogPost(sort: {fields: date, order: DESC}) {
    nodes {
        name
        date(formatString: "D M Y")
        category {
          name
        }
        featureImage {
          gatsbyImageData
        }
    }
  }
  allDatoCmsBlogCategory {
    nodes {
      name
      id
    }
  }
  resources:allDatoCmsHelpfulResourcesAll {
    nodes {
      resources {
        title
        websiteLink
        image {
          gatsbyImageData
        }
      }
    }
  }
}

`


export default BlogPage