import React, { useState  } from 'react'
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import Arrow from "../components/partials/arrow"

const Resources = ({ resources }) => {

    const [filteredList, setFilteredList] = useState(resources.nodes[0].resources.slice(0, 3))
    const [loadMore, setLoadMore] = useState(true)
    const allPosts = resources.nodes[0].resources
    const threePosts = resources.nodes[0].resources.slice(0, 3)

    const changePostsLoaded = () => {
        if (loadMore) {
            setFilteredList(allPosts)
            setLoadMore(false)
        } else {
            setFilteredList(threePosts)
            setLoadMore(true)
        }
    }

    return (
        <div className="all--services">
            <div className="title-area text-center p20">
                <h2 className="m0 mt40 mb40 h1">Helpful Resources</h2>
            </div>
            <div className="resource--list p20">
                {filteredList.map((resource, index) => {
                    return (
                        <Link to={resource.websiteLink} className="link" target="_blank" key={index}>
                            <div className="helpful--resources p20 bb1 flex align-center m-wrap">
                                <div className="image-wrapper max-150 w-100 m-hide">
                                    <div className="ratio-1-2 pos-rel" style={{ backgroundColor: '#ECECEC' }}>
                                        <GatsbyImage image={resource.image?.gatsbyImageData} alt={resource?.title} className="bg-image" />
                                    </div>
                                </div>
                                <div className="title-area pl20 ml20 mp0 m-m0 m-100">
                                    <p className="h3 m0 mb10">{resource.title}</p>
                                </div>
                                <div className="read-more mla m-ml0 flex align-center">
                                    Visit Website <Arrow />
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
            <div className="flex mt20">
                <button className="button black ma" onClick={changePostsLoaded}>{ loadMore ?'Load More' : 'Show Less'}</button>
            </div>
            <div className="spacer half"></div>
        </div>
    )
}
export default Resources 